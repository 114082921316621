import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NotificationsService } from 'projects/libraries/syslink-components/src/lib/notifications/notifications.service';
import { CustomerCatalog } from '../../../customer-catalogs/customer-catalog.model';
import { CustomerCatalogsService } from '../../../customer-catalogs/customer-catalog.service';
import { Product } from '../../product.model';
import { ModalComponent } from 'projects/libraries/syslink-components/src/public-api';
import { CustomerCatalogGridComponent } from '../../../customer-catalogs/customer-catalog-grid/customer-catalog-grid.component';
import { ProductsService } from '../../product.service';
@Component({
  selector: 'app-product-customer-catalogs',
  templateUrl: './product-customer-catalogs.component.html',
  styleUrls: ['./product-customer-catalogs.component.scss'],
})
export class ProductCustomerCatalogsComponent {
  @Input() public element: Product = new Product();
  @Output() public elementChange: EventEmitter<any> = new EventEmitter<any>();

  public customerCatalog: CustomerCatalog = new CustomerCatalog();

  @ViewChild('editModal') public editModal: ModalComponent =
    new ModalComponent();
  @ViewChild('customerCatalogGrid')
  public customerCatalogGrid?: CustomerCatalogGridComponent;
  public insertLineId: number = 1;

  constructor(private customerCatalogsService: CustomerCatalogsService,
    private productsService: ProductsService) { }

  public async initModalDatas(item?: CustomerCatalog) {
    this.customerCatalog =
      item ??
      (await this.customerCatalogsService.getInstanceWithProduct(
        this.element,
        item
      ));
  }

  // Create
  // ------
  public async onCreateBtnClicked() {
    await this.initModalDatas();
    this.editModal.open({ newItem: true });
  }

  public async onCreate(item: any) {
    if (
      this.canCreateOrUpdate(item, this.element.FilteredCustomerCatalogs) ==
      false
    )
      return;

    item.Id = -this.insertLineId;
    this.insertLineId++;
    item = this.productsService.computeExTaxNetPrice(this.element.Price ?? 0, item);
    let indexToRemove = this.element.FilteredCustomerCatalogs.findIndex(
      (l: CustomerCatalog) =>
        ((l.CustomerId &&
          item.CustomerId &&
          l.CustomerId.Id == item.CustomerId.Id) ||
          (l.PriceListId &&
            item.PriceListId &&
            l.PriceListId.Id == item.PriceListId.Id)) &&
        (l.ProductId == undefined || l.ProductId == null)
    );
    if (indexToRemove >= 0) {
      this.element.FilteredCustomerCatalogs =
        this.element.FilteredCustomerCatalogs.filter(
          (item: any, index: any) => index !== indexToRemove
        );
    }

    this.element.FilteredCustomerCatalogs.push(item);
    this.reloadGrid();
    this.element = this.productsService.reloadPrice(this.element);
    this.editModal.close();
  }
  // ----------------------------------------------

  // Update
  // ------
  public async onBeforeUpdate(event: any) {
    if (!event.key || !this.element.FilteredCustomerCatalogs) return;

    var newList = JSON.parse(JSON.stringify(this.element.FilteredCustomerCatalogs));
    let index: number = this.element.FilteredCustomerCatalogs?.findIndex(
      (l: CustomerCatalog) => l.Id == (event.oldData?.Id ?? event.key)
    );

    var elementToUpdate = newList[index];

    if (!elementToUpdate.ProductId) {
      elementToUpdate.ProductId = new Product({ Id: this.element.Id });
      elementToUpdate.ProductCategoryId = undefined;
      elementToUpdate.Id = -this.insertLineId;
      this.insertLineId++;
    }

    const newData = event.newData;
    const oldData = event.oldData;
    if (index < 0) return;

    for (const key in newData) {
      if (newData.hasOwnProperty(key) && oldData.hasOwnProperty(key)) {
        if (newData[key] !== oldData[key]) {
          switch (key) {
            case 'CustomerId':
              elementToUpdate.CustomerId = newData[key];
              elementToUpdate.PriceListId = undefined;
              break;
            case 'PriceListId':
              elementToUpdate.PriceListId = newData[key];
              elementToUpdate.CustomerId = undefined;
              break;
            case 'ProductCategoryId':
              NotificationsService.sendErrorMessage(
                'You can update product category'
              );
              break;
            default:
              (elementToUpdate as any)[key] = newData[key];
              elementToUpdate = this.productsService.computeExTaxNetPrice(this.element.Price ?? 0, elementToUpdate);
             
          }
        }
      }
    }
    if (
      this.canCreateOrUpdate(
        elementToUpdate,
        newList
      ) == false
    ) {
      this.customerCatalogGrid?.grid?.grid?.instance.refresh();
      event.cancel = true;
      return;
    }

    this.element.FilteredCustomerCatalogs[index] = elementToUpdate;
    this.element = this.productsService.reloadPrice(this.element);
    this.reloadGrid();
  }
  // ----------------------------------------------

  // Delete
  // ------
  public onDeleteBtnClicked(e: any) {
    if (!e.data.ProductId) {
      NotificationsService.sendInfo(
        'You can not delete customer catalog not linked to this product'
      );
      e.cancel = true;
      return;
    }

    this.element.FilteredCustomerCatalogs =
      this.element.FilteredCustomerCatalogs.filter(
        (l: CustomerCatalog) => l.Id !== e.key
      );
    this.element = this.productsService.reloadPrice(this.element);
  }

  // ----------------------------------------------

  private reloadGrid() {
    if (!this.customerCatalogGrid) return;
    this.customerCatalogGrid.customerCatalogs =
      this.element.FilteredCustomerCatalogs;
    this.customerCatalogGrid.grid?.grid?.instance.refresh();
  }

  private canCreateOrUpdate(
    item: CustomerCatalog,
    list: CustomerCatalog[]
  ): boolean {
    if (
      item.CustomerId &&
      item.CustomerId.Id &&
      item.PriceListId &&
      item.PriceListId.Id
    ) {
      NotificationsService.sendErrorMessage(
        'You cannot create a customer catalog with a customer and a price list'
      );
      return false;
    }

    if (!item.CustomerId && !item.PriceListId) {
      NotificationsService.sendErrorMessage(
        'A customer catalog must be linked to a customer'
      );
      return false;
    }

    var index = list.findIndex((l: CustomerCatalog) => {
      return (
        l.Id != item.Id &&
        ((l.CustomerId && item.CustomerId && l.CustomerId.Id == item.CustomerId.Id && !l.PriceListId && !item.PriceListId) ||
          (l.PriceListId && item.PriceListId && l.PriceListId.Id == item.PriceListId.Id && !l.CustomerId && !item.CustomerId)) &&
        ((l.ProductId && item.ProductId && l.ProductId.Id == item.ProductId.Id && !l.ProductCategoryId && !item.ProductCategoryId) ||
          (l.ProductCategoryId && item.ProductCategoryId && l.ProductCategoryId.Id == item.ProductCategoryId.Id && !l.ProductId && !item.ProductId))
      );
    });
    if (index >= 0) {
      NotificationsService.sendErrorMessage(
        'This customer catalog already exist'
      );
      return false;
    }

    return true;
  }
}